
import { defineComponent } from 'vue';

import MainLogo from '@/layout/header/partials/MainLogo.vue';
import ForgotPasswordForm from '@/components/forms/forgot-password/ForgotPasswordForm.vue';

export default defineComponent({
  name: 'forgot-password-page',
  components: {
    MainLogo,
    ForgotPasswordForm,
  },
});
